import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { Majorlist, Addmajorlist, MajorRead, Majorupdate, Majordel } from '@/api/ceshi_xiugai/product';
import { schoolList, getMajorBiz } from '@/api/PublicAPI';
import mailListEs from '@/components/StaffSelect/mailListRadioEs1';
import Upload from '@/components/Upload/SingleImage4';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      //系统教程
      title: '专业管理',
      tableData: [],
      Alltableoptions: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      schoolSearch: '',
      is_charge: '',
      type: '',
      majorName: '',
      adminSearch: '',
      schoolOptions: [],
      schoolOptions1: [],
      school_name: '',
      school_page: 1,
      //
      school_pagesize: 0,
      school_name1: '',
      school_page1: 1,
      //
      school_pagesize1: 0,
      charge_Array: [{
        id: 1,
        name: '是'
      }, {
        id: 2,
        name: '否'
      }],
      type_Array: [{
        id: 1,
        name: '学费'
      }, {
        id: 2,
        name: '定金'
      }, {
        id: 3,
        name: '报名费'
      }, {
        id: 4,
        name: '资料费费'
      }, {
        id: 5,
        name: '语言培训费费'
      }, {
        id: 6,
        name: '普通留学申请费'
      }],
      editForm: {
        title: '',
        // 专业名称
        school: '',
        //
        admin: '',
        charge: '2',
        majorYear: '',
        majorType: '1',
        feeType: '',
        majorCondition: '',
        lanCondition: '',
        graCondition: '',
        file: '' // 附件
      },
      textMap: {
        update: '编辑专业',
        create: '新增专业'
      },
      ruleForm: {},
      dialogStatus: '',
      dialogFormVisible: false,
      rules: {
        title: [{
          required: true,
          message: '请输入专业名称',
          trigger: 'blur'
        }],
        school: [{
          required: true,
          message: '请选择院校',
          trigger: 'change'
        }],
        admin: [{
          required: true,
          message: '请选择院校管理员',
          trigger: 'change'
        }],
        charge: [{
          required: true,
          message: '请选择是否参与收费',
          trigger: 'change'
        }]
      },
      row: undefined,
      adminName: '',
      tempId: '',
      value1: '',
      fileList: [],
      text: [],
      condition: [],
      staffResultEs: [],
      mailListVisibleEs: false,
      radioEs: false,
      language_enroll_factor: [],
      major_enroll_factor: [],
      major_graduation_factor: [],
      pay_Array: [],
      readVisible: false,
      disabledShow: false
    };
  },
  components: {
    Head: Head,
    Upload: Upload,
    mailListEs: mailListEs
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList();
      this.getSchool();
      this.getMajor();
    } else {}
  },
  methods: {
    formatter: function formatter(row, column) {
      return row.title;
    },
    getSchool: function getSchool() {
      var _this = this;
      schoolList({
        page: String(this.school_page),
        pagesize: String(this.school_pagesize)
      }).then(function (res) {
        _this.schoolOptions = res.data.school_list;
        _this.schoolOptions1 = res.data.school_list;
      });
    },
    getMajor: function getMajor() {
      var _this2 = this;
      getMajorBiz().then(function (res) {
        _this2.pay_Array = res.data.pay_type;
        _this2.major_graduation_factor = res.data.major_graduation_factor;
        _this2.language_enroll_factor = res.data.language_enroll_factor;
        _this2.major_enroll_factor = res.data.major_enroll_factor;
      });
    },
    GetList: function GetList() {
      var _this3 = this;
      Majorlist({
        pagesize: this.query.pagesize,
        page: this.query.page,
        is_charge: this.is_charge,
        school_id: this.schoolSearch,
        pay_type: String(this.type),
        title: this.majorName,
        administrators: this.adminSearch
      }).then(function (respomse) {
        _this3.tableData = respomse.data.data;
        _this3.query.total = respomse.data.total;
      });
    },
    //查询
    queryClick: function queryClick() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.query.page = 1;
        _this4.GetList();
      });
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this5.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this6.GetList();
      });
    },
    resetTemps: function resetTemps() {
      var _this7 = this;
      //清空班级弹窗
      this.editForm = {
        id: '',
        title: '',
        // 专业名称
        school: '',
        //
        admin: '',
        charge: '2',
        majorYear: '',
        majorType: '1',
        feeType: '',
        majorCondition: '',
        lanCondition: '',
        graCondition: '',
        file: '' // 附件
      };
      this.fileList = [];
      this.readVisible = false;
      this.adminName = '';
      this.$nextTick(function () {
        _this7.$refs['ruleForm'].clearValidate();
      });
      this.text = [];
    },
    //新建按钮
    handleAddClass: function handleAddClass() {
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.readVisible = true;
      // Object.keys(this.editForm).forEach((key) => (this.editForm[key] = ''));
    },
    //新建专业
    createData: function createData(formName) {
      var _this8 = this;
      var formEor = this.editForm;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            title: formEor.title,
            school_id: formEor.school,
            is_charge: formEor.charge,
            maior_admin_user: Number(formEor.admin),
            major_year: formEor.majorYear,
            full_time: formEor.majorType,
            pay_type: String(formEor.feeType),
            major_enroll_factor: String(formEor.majorCondition),
            language_enroll_factor: String(formEor.lanCondition),
            major_graduation_factor: String(formEor.graCondition),
            brochures_attachment_id: formEor.file
          };
          Addmajorlist(data).then(function (res) {
            _this8.dialogFormVisible = false;
            _this8.$notify({
              title: '成功',
              message: '新建专业成功',
              type: 'success',
              duration: 2000
            });
            _this8.GetList();
            _this8.resetTemps();
          });
        } else {
          return false;
        }
      });
    },
    mailList: function mailList() {
      this.radioEs = true;
      this.mailListVisibleEs = true;
      this.disabledShow = true;
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      this.adminName = String(resultNameArr);
      this.editForm.admin = String(result);
      this.text = result;
      this.disabledShow = false;
    },
    cancel: function cancel() {
      this.disabledShow = false;
    },
    enclosure: function enclosure(val) {
      //获取附件id
      this.editForm.file = this.editForm.file + ',' + String(val);
      var file = this.editForm.file.split(',');
      this.editForm.file = _toConsumableArray(new Set(file));
      this.editForm.file = String(this.editForm.file);
    },
    remove: function remove(a, b) {
      var c = a.split(','),
        d = '';
      for (var i = 0; i < c.length; i++) {
        d += c[i] == b ? '' : ',' + c[i];
      }
      return d.length > 0 ? d.substring(1) : '';
    },
    delFile: function delFile(val) {
      this.editForm.file = this.remove(this.editForm.file, val);
    },
    //编辑
    handleupdataClass: function handleupdataClass() {
      var _this9 = this;
      if (this.Alltableoptions.length !== 0) {
        if (this.Alltableoptions.length == 1) {
          this.dialogStatus = 'update';
          this.dialogFormVisible = true;
          MajorRead({
            id: String(this.tempId)
          }).then(function (res) {
            _this9.readVisible = true;
            var data = res.data;
            _this9.fileList = data.brochures_attachment;
            _this9.text.push(data.maior_admin.id);
            var payType, lanFactor, marFactor, enrollFactor, schoolId, majorType;
            if (data.pay_type) {
              if (data.pay_type.length > 1) {
                payType = data.pay_type.split(',').map(function (item) {
                  return item - 0;
                });
              } else {
                payType = [Number(data.pay_type)];
              }
            } else {
              payType = '';
            }
            if (data.language_enroll_factor) {
              if (data.language_enroll_factor.length > 1) {
                lanFactor = data.language_enroll_factor.split(',').map(function (item) {
                  return item - 0;
                });
              } else {
                lanFactor = [Number(data.language_enroll_factor)];
              }
            } else {
              lanFactor = '';
            }
            if (data.major_graduation_factor) {
              if (data.major_graduation_factor.length > 1) {
                marFactor = data.major_graduation_factor.split(',').map(function (item) {
                  return item - 0;
                });
              } else {
                marFactor = [Number(data.major_graduation_factor)];
              }
            } else {
              marFactor = '';
            }
            if (data.major_enroll_factor == 0) {
              enrollFactor = '';
            } else {
              enrollFactor = data.major_enroll_factor;
            }
            if (data.school_id == 0) {
              schoolId = '';
            } else {
              schoolId = data.school_id;
            }
            if (data.full_time == 0) {
              majorType = '';
            } else {
              majorType = String(data.full_time);
            }
            _this9.editForm = {
              title: data.title,
              school: schoolId,
              charge: String(data.is_charge),
              admin: data.maior_admin.id,
              majorYear: String(data.major_year),
              majorType: majorType,
              feeType: payType,
              majorCondition: enrollFactor,
              lanCondition: lanFactor,
              graCondition: marFactor,
              file: String(data.brochures_attachment_id)
            };
            _this9.adminName = data.maior_admin.realname;
          });
        } else {
          this.$message('只能选择一个进行编辑');
        }
      } else {
        this.$message('请一个进行编辑');
      }
    },
    updateData: function updateData(formName) {
      var _this10 = this;
      var formEor = this.editForm;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            id: String(_this10.tempId),
            title: formEor.title,
            school_id: formEor.school,
            is_charge: formEor.charge,
            maior_admin_user: Number(formEor.admin),
            major_year: formEor.majorYear,
            full_time: formEor.majorType,
            pay_type: String(formEor.feeType),
            major_enroll_factor: String(formEor.majorCondition),
            language_enroll_factor: String(formEor.lanCondition),
            major_graduation_factor: String(formEor.graCondition),
            brochures_attachment_id: formEor.file
          };
          Majorupdate(data).then(function (res) {
            _this10.$notify({
              message: '编辑成功',
              type: 'success'
            });
            _this10.$nextTick(function () {
              _this10.GetList();
            });
            _this10.dialogFormVisible = false;
          }).catch(function () {
            _this10.dialogFormVisible = false;
          });
        }
      });
    },
    //删除
    handledelClass: function handledelClass() {
      var _this11 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('此操作将永久删除专业，是否继续？', '提示', {
          type: 'warning'
        }).then(function () {
          Majordel({
            id: String(_this11.tempId)
          }).then(function (res) {
            _this11.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this11.$nextTick(function () {
              //获取列表方法
              _this11.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }

      // let arr = [];
      // val.forEach((item) => {
      //     arr.push(item.id);
      // });
      // this.tempId = arr;
      // this.editForm = Object.assign({}, this.Alltableoptions[0]);
    },
    InformationEditor: function InformationEditor() {
      this.InformationBox = false;
      this.dialogFormVisible = true;
    },
    // 详情删除按钮
    InstitutionInformationDeletion: function InstitutionInformationDeletion() {
      var _this12 = this;
      Majordel({
        id: this.row.id
      }).then(function (res) {
        _this12.$notify({
          message: '删除成功',
          type: 'success'
        });
        _this12.$nextTick(function () {
          //获取列表方法
          _this12.GetList();
        });
        _this12.InformationBox = false;
      });
    }
  }
};